






















div.latest-replies {
  .content {
    font-size: 0.9em;
    padding: 1em;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li:not(:first-child) {
      margin-top: 0.5em;
    }
  }
}
