






















div.not-found {
  div {
    padding-left: 30px;
  }
}
