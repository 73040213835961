/* Customize your colors here. */

$background_color: #F5F5F5;
$font_color: #000000;
$button_color: #6E6C98;
$card_title_background_color: #252350;
$card_title_font_color: #FFFFFF;
$card_background_color: #FFFFFF;
$card_font_color: 000000;
$link_color: #252350;
$table_border_color: mix(#252350, white, 70%);
$table_row_hover_color: mix(#252350, white, 4%);
$loading_indicator_color: #888888;
$input_border_color: #6E6C98;
$avatar_fallback_background_color: #6E6C98;
$avatar_fallback_text_color: #FFFFFF;
$progress_bar_color: #6E6C98;
