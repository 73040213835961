



















div.tags-list {
  ul {
    list-style: none;
    padding: 0 0.5em;
  }

  li {
    display: inline-block;
    font-size: 0.8em;
    padding: 0 0.5em;
  }
}
