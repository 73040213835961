

























































































































































































@import '../style/global.scss';
@import '../style/form-table.scss';

div.reply {
  div.content {
    padding: 0 1em 0 1em;
  }
  table th {
    text-align: right;
    width: 90px;
    vertical-align: top;
    font-weight: normal;
  }
  table {
    width: 90%;
    border-spacing: 5px;
  }
  table textarea.content {
    height: 8em;
  }

  div.raw-content {
    white-space: pre;
  }

  ul.replies-list {
    padding: 0;
    list-style: none;

    > li {
      position: relative;
      display: flex;
      padding: 0.6em 1em 0.1em 0.3em;
      margin: 0.5em 0 0.5em 0;
      // background-color: rgb(245, 245, 245);
      border-radius: 2px;
      line-height: 1.2em;

      pre {
        background-color: inherit;
        border: rgb(235, 235, 235);
        border-radius: 0;
      }

      a.reply-to {
        font-size: 12px;
        position: absolute;
        bottom: 5px;
        right: 8px;
        opacity: 0;
        transition: all ease 0.3s;
        &:not(:hover) {
          border-bottom: 1px solid transparent;
        }
      }

      div.avatar, div.github-avatar {
        width: 40px;
        height: 40px;
      }

      div.avatar {
        flex-grow: 0;
        flex-shrink: 0;
        border-radius: 20px;
        overflow: hidden;
        margin-top: 5px;
        margin-right: 1em;
        position: relative;
      }

      div.github-avatar, div.fallback-avatar {
        position: absolute;
      }

      div.github-avatar {
        z-index: 2;
        background-size: cover;
      }

      div.fallback-avatar {
        z-index: 1;
        user-select: none;
        line-height: 40px;
        width: 40px;
        text-align: center;
        font-size: 20px;
        background-color: $avatar_fallback_background_color;
        color: $avatar_fallback_text_color;
        user-select: none;
      }
    }

    > li:hover {
      a.reply-to {
        opacity: 1;
        transition: none;
      }
    }

    div.name {
      font-weight: bold;
    }
    div.date, div.name {
      font-size: 0.8em;
      color: grey;
    }
  }

  button {
    font-size: 12px;
    margin-right: 5px;
  }
}
