
























































































































@import './style/global.scss';

body {
  margin: 0;
  color: $font_color;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  background-color: $background_color;
  font-family: Microsoft Yahei UI;
}

#app {
  max-width: 1280px;
  padding: 0 1em 0 1em;
  margin: 0 auto;
}

footer {
  font-size: 12px;
  color: grey;
  text-align: center;
  padding: 0 0 14px 0;
  // border-top: 2px solid lightgrey;
  // margin: 0 36px;
}

#progress-bar {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: $progress_bar_color;
  box-shadow: 0 0 5px $progress_bar_color;
  height: 2px;
  // transition: all ease .3s;
  opacity: 0;

  &.updating {
    transition: all linear .1s;
    opacity: 1;
  }

  &.finishing {
    transition: all ease .7s;
  }
}

@media screen and (min-width: 800px) {
  #app {
    display: flex;
  }

  #left {
    flex-shrink: 0;
    flex-grow: 0;
    width: 300px;
    height: fit-content;
    padding-right: 15px;
    position: relative;
    div#left-wrapper {
      width: 300px;
      position: absolute;
    }
  }

  #right {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    padding: 0 4px 0 4px;
  }
}

@media screen and (max-width: 800px) {
  #app {
    display: block;
    padding: 0;
  }

  #left {
    width: 100%;
  }

  .hide-on-mobile {
    display: none;
  }

  #progress-bar {
    box-shadow: 0 0 10px $progress_bar_color;
    height: 4px;
  }
}

@import './style/custom.scss';
