
































































@import '../style/global.scss';

div.side-bar {
  /* Mobile devices */
  @media screen and (max-width: 800px) {
    min-height: 40px;
  }
}

@media screen and (max-width: 800px) {
  div.drawer {
    z-index: 100;
    box-sizing: border-box;
    width: 80vw;
    height: calc(100vh - 40px); /* title bar */
    position: fixed;
    top: 40px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; // iOS
    max-width: 300px;
    transform: translateX(-100vw);
  }

  div.drawer, div.drawer-container {
    z-index: 50;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all ease 0.3s;
  }

  div.drawer-container {
    top: 0;
  }

  div.drawer::-webkit-scrollbar {
    display: none;
  }

  div.drawer-container {
    pointer-events: none;
  }

  div.drawer-container.active {
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: initial;
  }

  div.drawer.active {
    transform: initial;
    background: white;
  }
}

header.title {
  text-align: center;
  position: relative;
  padding-top: 1px;
  z-index: 200;

  > a, > a:hover {
    // Fix IE11 & Edge
    border-bottom: none;
  }
  h1, h2 {
    font-weight: normal;
  }
  h1 {
    font-size: 2em;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
    letter-spacing: 2px;
    font-weight: 300;
  }
  h2 {
    font-size: 20px;
  }

  svg g {
    fill: $card_title_background_color;
    fill-opacity: 0.8;
    stroke: none;
  }

  /* Mobile devices */
  @media screen and (max-width: 800px) {
    position: fixed;
    width: 100vw;
    background: rgba(white, 0.9);

    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);

    h1 {
      margin: 0;
      height: 40px;
      line-height: 40px;
      font-size: 24px;
    }

    h2 {
      display: none;
    }

    svg.drawer-trigger {
      height: 20px;
      width: 20px;
      position: absolute;
      top: 0;
      left: 0;
      padding: 12px 10px 8px 10px;
    }
  }

  @media screen and (min-width: 800px) {
    svg.drawer-trigger {
      display: none;
    }
    div.avatar {
      width: 100px;
      height: 100px;
      margin: 20px auto -10px auto;
      background-size: cover;
      border-radius: 50px;
      // border: 2px solid red;
      box-shadow: 0 0 20px rgba(black, 0.5);
    }
  }
}
