



















header.search {
  display: flex;

  input.search-text {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
