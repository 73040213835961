



















































.page-view {
  article.page-content {
    padding: 15px;
  }
}
