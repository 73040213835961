













































































































































div.post-view {
  > div.card {
    padding: 0em;
    .content {
      padding: 20px;
    }
  }

  h2.post-title {
    font-size: 1.25em;
    font-weight: normal;
    margin-top: .25em;
  }

  div.post-meta {
    font-size: 0.9em;
    line-height: 1.5em;
    word-wrap: break-word;
    word-break: break-all;
  }

  article.post-content {
    padding: 0 1em 0 1em;
  }

  article {
    line-height: 1.5em;
    margin-bottom: 1em;
  }

  article:not(:first-child) {
    margin-top: 1em;
  }

  article > *:first-child {
    margin-top: 0;
  }

  article > *:last-child {
    margin-bottom: 0;
  }

  div.post-meta > span {
    margin-right: 20px;
  }

  header:not(.image-overlay) div.post-meta > span {
    color: #333;
  }

  p.indent {
    text-indent: 2em;
  }

  header.image-overlay {
    padding: 20px;
    box-sizing: border-box;
    // position: absolute;
    bottom: 0px;
    background: linear-gradient(to bottom, rgba(black, 0), rgba(black, 0.5));
    width: 100%;
    div.post-meta > span {
      color: #fff;
    }
    * {
      $shadow-color: #333;
      color: #fff;
      text-shadow: $shadow-color 1px 0px 1px, $shadow-color 0px 1px 1px, $shadow-color 0px -1px 1px, $shadow-color -1px 0px 1px;
    }
  }
  
  div.cover-image {
    display: block;
    position: relative;
    background-size: cover;
    background-position: center;
    width: 100%;
    > * {
      display: inline-block;
      vertical-align: bottom;
    }
  }

  div.placeholder {
    padding-top: 30%;
  }

  article.protect-article {
    input {
      margin: 1em 0;
      width: 200px;
      padding: 5px;
      font-size: 12px;
      // margin-right: 1em;
    }

    input:focus, textarea:focus {
      outline: none;
    }

    input, textarea {
      border: none;
      border-radius: 0;
      border: 1px solid #888888;
      background: rgba(0, 0, 0, 0);
    }
  }
}
