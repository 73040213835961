






















































































@import '../style/global.scss';
nav.pagination {
  $size: 28px;
  position: relative;
  margin: 0;
  padding: 20px;
  height: $size;
  ul {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin-left: 85px;
    position: absolute;
    right: 10px;
    margin: 0;
  }
  li {
    display: inline-block;
    min-width: $size;
    min-height: $size;
    font-size: 14px;
    line-height: $size;
    text-align: center;
    margin-left: 0.3em;
    cursor: pointer;
    a:not(.disabled) {
      background-color: rgb(245, 245, 245);
      color: black;
      box-shadow: none;
    }
    a {
      display: inline-block;
      padding: 0 0.75em 0 0.75em;
    }
    a.disabled {
      cursor: initial;
    }
    a:hover {
      border: none;
    }
  }
}
