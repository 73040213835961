@import './color.scss';

table.form-table {
  width: 100%;

  border-spacing: 10px;
  td.label {
    width: 90px;
    text-align: right;
    vertical-align: top;
  }

  input.full {
    width: 100%;
  }

  input:focus, textarea:focus {
    outline: none;
  }

  input {
    padding: 2px;
  }

  input, textarea {
    border: none;
    border-radius: 0;
    border: 1px solid #888888;
    background: rgba(0, 0, 0, 0);
  }

  textarea.content {
    width: 100%;
    height: 50vh;
    line-height: 1rem;
    font-size: 1rem;
    resize: vertical;
  }
}