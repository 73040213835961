






















div.widgets {
  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: block;
    font-size: 1em;
    line-height: 1.5em;
  }

  .content {
    padding: 1em;
  }
}
